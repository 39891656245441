import { DocumentNode, gql } from '@apollo/client';
import * as React from 'react';

import { CurrentProvider_TenantFragment } from './graphql-types/CurrentProvider_TenantFragment';
import { CurrentProvider_UserFragment } from './graphql-types/CurrentProvider_UserFragment';

const USER_FRAGMENT = gql`
  fragment CurrentProvider_UserFragment on User {
    _id
    acceptedCodeOfConduct
    integrationId
    displayName
    integrationIsMember
    canAccessCommunity
    canAccessCommunityReadOnly
    canAccessDMPage
    role
    accountStatus
    rosterOrganization {
      _id
      sponsorship {
        currentTier {
          analyticsTierType
        }
      }
      currentUserOrganizationPermission {
        canManageOrganizationSponsorship
        canManageOrganizationProfile
        canManageOrganizationMembership
      }
    }
    onboardingFlowState {
      isSkippedOrCompleted
    }
    analyticsUserType
    email
    analyticsRoleType
  }
`;

const TENANT_FRAGMENT = gql`
  fragment CurrentProvider_TenantFragment on Tenant {
    _id
    absoluteUrl
    accountCreationPermission
    acronym
    loginContactMessage
    associationCode
    codeOfConduct
    communityAccessConfig
    demo
    displayName
    isFullyAddressable
    maintenanceModeOn
    obnoxiousCodeOfConduct
    organizationEntityName
    sponsorDirectoryAccessConfig
    featureFlags {
      cms
      conferences
    }
    currentUserPermissions {
      canAccessMemberDirectory
      canAccessEventsFeed
    }
    featureFlags {
      ads
      cms
      conferences
      disableFtue
      historicalDataImport
      invoicing
      memberManagement
      memberManagementAdminOnly
      mobileGrowth
      reporting
      setReferrerPolicy
      topSessionBanner
    }
    paidModules {
      memberManagement
    }
    logo {
      _id
      imageProxy(input: { h: 80, w: 416 })
    }
    logoIcon {
      _id
      imageProxy(input: { h: 80, w: 80, fit: "fill", fill: "solid" })
    }
  }
`;

export type Current = {
  loading: boolean;
  user: CurrentProvider_UserFragment | null | undefined;
  tenant: CurrentProvider_TenantFragment | null | undefined;
};

const CurrentContext = React.createContext<Current | null>(null);

export const CurrentProvider: React.FC<Current> & {
  userFragment: DocumentNode;
  tenantFragment: DocumentNode;
} = ({ children, loading, user, tenant }) => {
  return (
    <CurrentContext.Provider value={{ loading, user, tenant }}>
      {children}
    </CurrentContext.Provider>
  );
};

CurrentProvider.userFragment = USER_FRAGMENT;
CurrentProvider.tenantFragment = TENANT_FRAGMENT;

export const useCurrent = () => {
  const context = React.useContext(CurrentContext);

  if (!context) {
    throw new Error(
      'The useCurrent() hook can only be executed inside a <CurrentProvider />'
    );
  }

  return context;
};
